.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon2 {
  width: 24px;
  height: 24px;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link3 {
  text-decoration: none;
}
.home-link6{
  text-decoration: underline;
}
.home-link6:hover{
  color: navy;
}
.home-hero {
  width: 1422px;
  height: 564px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-content-container {
  width: 1555px;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/twitter_1673017375-600h.jpeg");
}
.home-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  font-style: normal;
  text-align: center;
  font-weight: 900;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-text {
  font-size: 45px;
}
.home-text01 {
  font-size: 45px;
}
.home-text02 {
  font-size: 45px;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-team {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text04 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-cards-container {
  width: 100%;
  height: 263px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-team-card {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-image2 {
  width: 100px;
  object-fit: cover;
}
.home-name {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.home-text06 {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
}
.home-position {
  color: var(--dl-color-gray-700);
}
.home-team-card1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-image3 {
  width: 100px;
  object-fit: cover;
}
.home-name1 {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.home-position1 {
  color: var(--dl-color-gray-700);
}
.home-team-card2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-image4 {
  width: 100px;
  object-fit: cover;
}
.home-name2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-position2 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-team-card3 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-image5 {
  width: 100px;
  object-fit: cover;
}
.home-name3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text13 {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
}
.home-position3 {
  color: var(--dl-color-gray-700);
}
.home-team-card4 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-image6 {
  width: 100px;
  object-fit: cover;
}
.home-name4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-position4 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-services {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-self: flex-start;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text18 {
  width: 539px;
  text-align: left;
}
.home-copperhead {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-image7 {
  width: 238px;
  height: 218px;
  object-fit: cover;
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.home-section-separator1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-contact {
  width: 100%;
  height: 389px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container3 {
  width: 381px;
  height: 286px;
  display: flex;
  align-self: flex-start;
  margin-top: 50px;
  align-items: flex-start;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 50px;
  flex-direction: column;
}
.home-text24 {
  font-size: 45px;
}
.home-text26 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.home-text27 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text28 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-link4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  text-decoration: underline;
}
.home-text29 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text30 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text37:hover {
  color: navy;
  text-decoration: underline;
}
.home-text31 {
  font-size: 30px;
}
.home-text32 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text33 {
  font-size: 30px;
}
.home-text34 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text35 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-image8 {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  object-fit: cover;
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.CSlink {
  text-decoration: underline;
}
.CSlink:hover {
  color: navy;
}
.home-container69 {
  width: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
}
.home-footer {
  color: f9f9f9;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link5 {
  display: contents;
}
.home-icon4 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon6 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-content-container {
    flex-direction: column-reverse;
  }
  .home-hero-text {
    width: 80%;
  }
  .home-team {
    height: 778px;
  }
  .home-cards-container {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-heading-container {
    width: 100%;
  }
  .home-heading-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-hero-text {
    width: 100%;
  }
  .home-team {
    height: 937px;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text04 {
    text-align: center;
  }
  .home-services {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
